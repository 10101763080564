




























































































import { PostStore } from '@/stores/post-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import Avatar from './avatar.vue'

@Observer
@Component({
  components: {
    Avatar,
    'og-card': () => import('@/modules/common/components/posts/og-card.vue'),
    'tag-category': () => import('@/components/tag-category.vue'),
    'post-location-block-no-action': () =>
      import('@/modules/common/components/posts/post-location-block-no-action.vue'),
    observer: () => import('vue-intersection-observer'),
  },
})
export default class PostGrid extends Vue {
  @Prop({ default: {} }) postStore!: PostStore
  get contentBlocks() {
    return this.postStore.contentBlocks
  }
  get contentMedias() {
    return this.postStore.contentMedias
  }

  forceShow = false
  postContentContainer
  postContentWrapper
  resizeObserver
  expandContentButton
  contentDivider

  onExpandContent() {
    //Increase view
    this.postStore.postViewIncrement()
    this.expandContentButton.classList.remove('display-block')
    this.expandContentButton.classList.add('display-none')
    this.forceShow = true
    this.postContentWrapper.classList.remove('post-content-wrapper-hidden')
    this.postContentWrapper.classList.add('post-content-wrapper-show')
    this.contentDivider.classList.remove('display-none')
    this.contentDivider.classList.add('display-block')
    this.$emit('onExpand')
  }

  focusVideo(entry) {
    if (entry.isIntersecting) {
      entry.target?.firstChild?.play()
    } else {
      entry.target?.firstChild?.pause()
    }
  }

  mounted() {
    this.postContentContainer = document.getElementById(`{post-content-container}-${this.postStore.post.id}`)
    this.expandContentButton = document.getElementById(`{expand-content-button}-${this.postStore.post.id}`)
    this.postContentWrapper = document.getElementById(`{post-content-wrapper}-${this.postStore.post.id}`)
    this.contentDivider = document.getElementById(`{content-divider}-${this.postStore.post.id}`)
    this.resizeObserver = new (window as any).ResizeObserver((entries) => {
      let rect = entries[0].contentRect
      let height = rect.height
      if (height > 747 && !this.forceShow) {
        this.expandContentButton.classList.remove('display-none')
        this.expandContentButton.classList.add('display-block')
      }
    })
    if (this.postContentContainer) this.resizeObserver.observe(this.postContentContainer)
  }

  beforeDestroy() {
    this.resizeObserver.unobserve(this.postContentContainer)
  }
}
